@import '../global/vars';

.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: $color-text-default;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'SegoeUI MDL2 Assets';
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: $color-text-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '\E00E';
}

[dir='rtl'] .slick-prev:before {
  content: '\E00E';
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '\E00F';
}

[dir='rtl'] .slick-next:before {
  content: '\E00F';
}

.slick-track {
  margin: 0;
}
