@import '../global/_vars';
@import '../global/_mixins';

// App specific modified bootstrap nav

.app__container {
  padding: 20px 40px;
}

.k-filter-menu .k-button.k-primary:focus {
  outline-color: #231f20;
}
.app__nav {
  margin-top: 20px;

  &__pad {
    margin-top: 40px !important;
    margin-bottom: 30px;
  }

  .nav-pills .nav-link {
    padding-top: 0;
    padding-bottom: 3px;
    color: #707070;
    border-radius: 0;
    font-weight: 500;
    opacity: 1;

    &:hover {
      background-color: #ffffff;
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #fff;
    color: #333333;
    border-bottom: 2px solid #2f80ed;

    .view__icon path {
      fill: #333333;
    }
  }

  &-views {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .nav-pills .nav-link {
      color: #828282;
    }
  }

  &-views_left {
    font-size: 12px;
    display: flex;
    margin-top: 15px;

    .nav-pills .nav-link {
      color: #828282;
    }
  }

  &-manager {
    .nav-link {
      font-size: 20px;
    }
  }
}

// App tile styles
.app__tile {
  background: $color-background;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
  padding: 20px;
  margin: 20px 0;
  font-family: $font;
  line-height: normal;

  &__ytd {
    padding: 0;
  }

  &__container {
    padding: 20px;
  }

  &__header {
    display: flex;
    align-items: center;

    .app__nav-views {
      margin-left: auto;
      margin-top: 0;
    }

    @include respond-to(mobile) {
      flex-wrap: wrap;
    }
  }

  &__heading {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }

  &__report {
    font-size: 14px;
    padding: 0 5px;
    text-align: right;
    color: $color-input-primary !important;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none !important;

    @include respond-to(mobile) {
      flex-basis: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }
}

.app__tile__new {
  margin-top: -20px;
}

.app__export__excel {
  display: block;
  position: relative;
}

// Legend specific styles
.legend {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &__clr {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &__icon {
      width: 20px;
      font-size: 20px;
      margin-right: 5px;
    }

    &__text {
      font-size: 11px;
      color: $color-text-tertiary;
    }
  }
}

.mint__legends-myteam {
  .legend {
    margin: 0;
  }
}

// PCC related styles
.tab__content {
  &__header {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }

  &__subhead {
    font-size: 12px;
    color: $color-text-secondary;
    margin-right: 15px;
  }
}

//Tool Tip
.stat__info {
  position: relative;
}

.stat__tooltip {
  position: absolute;
  font-weight: normal;
  display: none;
  background: $color-background;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  z-index: 100;
  padding: 15px;
  text-align: left;
  color: $color-text-primary;
  width: 200px;
  right: -30px;
  top: 30px;

  &__header {
    font-size: 14px;
  }

  &__text {
    font-size: 12px;
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    right: 28.5px;
    top: -10px;
    border: 10px solid $color-background;
    box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
  }

  &-tt-right {
    &::before {
      margin-top: 33px;
      margin-right: 160px;
      box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }

  &-left {
    left: -15px;
    right: unset;
    &-tt-right {
      margin-top: -54px;
      margin-right: -176px;
    }

    &::before {
      right: unset;
    }
  }

  &-widen {
    width: 400px;
  }

  &-widest {
    width: 620px;
  }
}

.tooltip {
  &__trigger {
    font-size: 13px;
    margin-left: 3px;
    color: $color-info-icon;
    border: 0;
    background: $color-background;
    padding: 0;
  }
}

.icon.icon-info {
  font-size: 13px;
  margin-left: 3px;
  color: #828282;
  top: 2px;
  display: block;

  &:hover + .stat__tooltip {
    display: block;
  }
  &:focus + .stat__tooltip {
    display: block;
  }
}

// Metric PCC/PCG block styles

.pcg__item {
  flex: 1;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:only-child {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &-single {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__desc {
      border: 1px solid $color-separator-3;
    }
  }

  &__metric {
    box-sizing: border-box;
    flex: 1;
    background: $color-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  &__chart {
    display: flex;
  }
}

// modal styles

.mint-modal-backdrop.modal-backdrop.fade {
  opacity: 0.5;
}

.mint-modal-window {
  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translate(0, 0);
    }
  }
}
.mint-modal-window-center {
  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translate(0, 0);
      margin: 250px auto;
    }
  }
}
.mint-modal-window-enlarge {
  &.show {
    opacity: 1;

    .modal-dialog {
      width: auto;
      margin: 50px 50px;
      transform: translate(0, 0);      
    }
  }
}
.modal-centred {
  display: flex !important;
  align-items: center;
}

.mint-modal-window-lg {
  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translate(0, 0);
      width: 900px;
    }
  }
}

.mint-modal-window-lg-mqch {
  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translate(0, 0);
      width: 1100px;
      top: 50px;

      .modal-content {
        border-radius: 1px;
      }
    }
  }
}

// rich text editor styles

.angular-editor-toolbar {
  font-size: 16px !important;
}
// app level banner styles

.mint__banner {
  background: $color-banner;
  padding: 10px;
  margin: 10px 20px;
}

//My Teams styles

.direct__level {
  &-1 {
    .direct__item > .direct {
      .utilization__quota__container {
        margin-left: 90px;
      }
    }
  }

  &-2 {
    .direct__item > .direct {
      .utilization__quota__container {
        margin-left: 60px;
      }
    }
  }

  &-3 {
    .direct__item > .direct {
      .utilization__quota__container {
        margin-left: 30px;
      }
    }
  }

  &-4 {
    .direct__item > .direct {
      .utilization__quota__container {
        margin-left: 0;
      }
    }
  }
}

.icon__ms {
  font-size: 20px;

  &.icon-compliance-signed {
    color: #26873a;
  }

  &.icon-compliance-notsigned {
    color: #c50000;
  }
}

// Slick Styles

.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: $color-text-secondary;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'SegoeUI MDL2 Assets';
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: $color-text-secondary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '\E00E';
}

[dir='rtl'] .slick-prev:before {
  content: '\E00E';
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '\E00F';
}

[dir='rtl'] .slick-next:before {
  content: '\E00F';
}

.slick-track {
  margin: 0;
}

.ubi-metric {
  .pcg__item:only-child {
    flex-grow: 1;
  }
}

.perf_metric_card_rubi {
  .rubi_perf_metric_card {
    .metric__weight {
      margin-top: 23px;
    }
    .metric__stat {
      margin-top: 30px;
    }
  }
}

// Multi select dropdown styles
.multiselect-dropdown {
  .dropdown-btn {
    border-radius: 0 !important;
  }
}

.flex_row {
  .multiselect-dropdown {
    min-width: 400px;
  }
}

// Ngb accordion styles

.grid {
  &__container {
    margin-top: 40px;

    .accordion {
      width: 100%;

      .card-header {
        background-color: #e5edf3;
        border: 1px solid #c5c5c5;
      }

      .card-body {
        display: block;

        .card-header {
          background-color: $color-background;
        }
      }

      .collapse__header {
        padding: 10px 10px;
      }

      &.level-1 {
        .card:not(:first-child):not(:only-child) {
          margin: 0px;
          border: 1px solid #c5c5c5;
        }
      }
    }
  }
}

.accordion {
  width: 100%;

  .card-header {
    background-color: $color-panel-accordian;
  }

  .card-body {
    display: flex;

    .card-header {
      background-color: $color-background;
    }
  }

  .collapse__header {
    padding: 24px 10px;
  }

  &.level-1 {
    .card:not(:first-child):not(:only-child) {
      margin-top: 10px;
    }
  }

  &.level-1 {
    &.mt {
      .card:first-child {
        margin-top: 10px;
      }
    }
  }

  &.level-2,
  &.level-3 {
    .card:not(:first-child):not(:only-child) {
      margin-top: 0;
    }
  }

  &.level-2 {
    .collapse__header {
      border-top: 2px solid #000000;
      border-bottom: 2px solid #000000;
    }
  }

  &.level-3 {
    .collapse__header {
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
    }
  }
}

// NgbModal footer button styles

.footer__btn {
  border: none;
  width: 76px;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;

  &-primary {
    color: #ffffff;
    background: $color-input-primary;
  }

  &-cancel {
    color: #666666;
    font-size: 13px;
    background: #f3f2f1;
  }

  &:focus {
    outline: 1px dashed #000000;
  }
}
