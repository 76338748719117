$break-mob: 320px;
$break-tablet: 768px;
$break-desktop: 1024px;
$break-medium: 1200px;
$break-large: 1920px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-mob) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-tablet) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (min-width: $break-mob) and (max-width: $break-tablet - 1) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-mob + 1) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $break-medium) {
      @content;
    }
  } @else if $media == large-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $media == high-contrast-active {
    @media screen and (-ms-high-contrast: Active) {
      @content;
    }
  } @else if $media == high-contrast-white-on-black {
    @media screen and (-ms-high-contrast: white-on-black) {
      @content;
    }
  } @else if $media == high-contrast-black-on-white {
    @media screen and (-ms-high-contrast: black-on-white) {
      @content;
    }
  }
}

// Box decoration
@mixin boxDec {
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
}

@mixin flexBoxCenterH {
  display: flex;
  align-items: center;
}

@mixin flexBoxCenterVH {
  display: flex;
  align-items: center;
  justify-content: center;
}
