//Font-family
$font: 'Segoe UI';
$font-regular: 'Segoe UI Regular', 'Segoe UI';
$font-semilight: 'Segoe UI Semilight', 'Segoe UI Light', 'Segoe UI';
$font-light: 'Segoe UI Light', 'Segoe UI';
$icon-font: 'Segoe UI Symbol';
$font-semibold: 'Segoe UI Semibold', 'Segoe UI';
$font-bold: 'Segoe UI bold', 'Segoe UI';
$font-mdl2: 'SegoeUI MDL2 Assets';

//Default colors
$color-background: #ffffff;
$color-container-background: #fafafa;
$color-brand: #00ae56;
$color-text-default: #000000;
$color-text-primary: #333333;
$color-text-secondary: #707070;
$color-text-tertiary: #4f4f4f;
$color-input-primary: #0078d4;
$color-input-inactive: #cccccc;
$color-link: #2f80ed;
$color-tab-active: #333333;
$color-tab-inactive: #828282;
$color-tab-inactive-2: #707070;
$color-tab-active-border: #2f80ed;
$color-separator: #f2f2f2;
$color-separator-2: #e7e7e7;
$color-separator-3: #e0e0e0;
$color-separator-4: #eeeeee;
$color-bar-default: #f2f2f2;
$color-pay-incentive-border: #27ae60;
$color-banner: #deecf9;
$color-border-default: #dddddd;
$color-info-icon: #828282;
$color-panel-accordian: #f2f2f2;

//AG Grid specific
$color-ag-border: #bdc3c7;

//Performance-colors
$BelowThreshold: #eb5757;
$AboveThreshold: #ffb900;
$AboveTarget: #27ae60;
$Old: #707070;
$AboveExcellence: #2f80ed;
$Neutral: #27ae60;
$RUBINeutral: #000000;
$OCPMetric: #999999;
$EstimatedEarnings: #212121;
$EstimatorBucket: #00ae56;

//Legend borders to make corlor accessiblity
$AboveThresholdBorder: 1px solid #d77f00;
$AboveThresholdBorderColor: #d77f00;

//PCC Multibar chart colors
$cus-rev: #023900;
$non-cus-rev: #00ae56;
$target: #3354a5;

//YTD Perf. by metric legend colors
$month-past: #669da9;
$month-current: #005b70;
$month-future: #bdbdbd;

//Earning Potential chart legend colors
$earn-potmil: #27ae60;
$curr-perf: #ffb900;
$curr-perf-border: 1px solid #d77f00;

//CAGR grid legend colors
$unlocked: #00ae56;
$locked: #000000;

//H2 quota colors
$h2-color-background: #dbdbdb;

//button colors
$color-button-primary: #0078d4;
$color-button-text-primary: #ffffff;
$color-button-secondary: #f3f2f1;
$color-button-text-secondary: #666666;
