@import '../styles/global/vars';

.toggle__btn {
  margin-left: 10px;

  &__container {
    display: flex;
    align-items: center;
    padding-right: 16px;
  }

  &__title {
    font-family: inherit;
    line-height: normal;
    color: #323130;
    font-size: 14px;

    &__beta {
      font-size: 10px;
      color: $color-input-primary;
      font-weight: bold;
    }
  }

  &__lbl {
    background: $color-input-inactive;

    &.ytd__lbl {
      background: $color-input-primary;
    }

    &.softcap__lbl {
      background: #a4262c;
    }

    border-radius: 10px;
    display: block;
    width: 40px;
    height: 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      right: 24px;
      top: 4px;
      width: 12px;
      height: 12px;
      background: $color-background;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }
}

.toggle__btn__inp:checked {
  & + .toggle__btn__lbl {
    background: $color-input-primary;
    transition: all 0.3s;

    &.softcap__lbl {
      background: #096e3b;
    }

    &::before {
      content: '';
      position: absolute;
      right: 4px;
      top: 4px;
      width: 12px;
      height: 12px;
      background: $color-background;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }
}

.softcap {
  &__aproved {
    label.toggle__btn__lbl {
      background: #096e3b !important;
    }

    .toggle__btn__title {
      color: #096e3b;
    }
  }

  &__declined {
    label.toggle__btn__lbl {
      background: #a4262c !important;
    }

    .toggle__btn__title {
      color: #a4262c;
    }
  }
}
