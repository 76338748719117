@import './_vars';

//Bar chart styles
.graph__container {
  position: relative;
  overflow-x: auto;
  width: 100%;
}

rect.bar-placeholder {
  fill: $color-bar-default;
}

.bar-clickable {
  cursor: pointer;
}

.x-axis .tick text tspan {
  font-family: $font;
  font-size: 12px;
  color: $color-text-primary;
}

.y-axis .tick:not(:first-of-type) line {
  stroke-dasharray: 5, 5;
}

.y-axis .tick:nth-child(odd) line {
  display: none;
}

.bar__label text,
text.bar__label {
  text-anchor: middle;
  font-size: 12px;
  fill: $color-text-primary;
}

g.layer ~ g.layer .bar-placeholder {
  display: none;
}

.wrap__text {
  display: flex;
  justify-content: center;
  padding: 5px;

  div {
    font-family: 'Segoe UI';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #333333;
    font-weight: 500;
  }
}

// Tool tip styles
.bar__tooltip {
  background: $color-background;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  font-family: 'Segoe UI';
  line-height: normal;
  position: absolute;
  display: none;
  z-index: 200;

  &__container {
    display: flex;
    padding: 15px;

    &-pcg {
      min-width: 500px;
      justify-content: space-between;
    }

    &-pcc {
      width: 320px;
      flex-direction: column;
    }

    &-pccAcc {
      width: 280px;
      flex-direction: column;
    }
  }

  &__left {
    border-right: 1px solid $color-separator;
    padding-right: 10px;
  }

  &__right {
    padding-left: 15px;
  }

  &__close {
    margin: 0 5px 0 10px;
    align-self: flex-start;
  }

  &__close svg {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &__header {
    font-family: inherit;
    line-height: inherit;
    font-size: 14px;
    color: $color-text-primary;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bar__tooltip-left.bar__tooltip::before {
  display: none;
}

.chart__tooltip {
  background: $color-background;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  font-family: 'Segoe UI';
  line-height: normal;
  position: absolute;
  display: none;
  z-index: 200;
  padding-left: 10px;
  padding-right: 10px;
}

.perf__data {
  font-family: inherit;
  line-height: inherit;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.perf__data__text {
  color: #828282;
}

.perf__data__val {
  color: $color-text-primary;
  margin-left: 5px;
}

.data__source__item {
  font-family: inherit;
  line-height: inherit;
  font-size: 12px;
}

.data__source__item:not(:last-child) {
  margin-bottom: 10px;
}

.data__source__header {
  color: $color-text-primary;
  font-size: 12px;
  font-weight: 500;
}

.data__source__value {
  color: $color-text-tertiary;
  font-size: 11px;
}

// PCG Chart
.pcg__item__chart {
  &.blw__th {
    .graph-main rect {
      fill: $BelowThreshold;
    }
  }

  .blw__th {
    &.graph-main rect {
      fill: $BelowThreshold;
    }
  }

  &.abv__th {
    .graph-main rect {
      fill: $AboveThreshold;
      stroke: $AboveThresholdBorderColor;
    }
  }

  .abv__th {
    &.graph-main rect {
      fill: $AboveThreshold;
      stroke: $AboveThresholdBorderColor;
    }
  }

  &.abv__tgt {
    .graph-main rect {
      fill: $AboveTarget;
    }
  }

  .abv__tgt {
    &.graph-main rect {
      fill: $AboveTarget;
    }
  }

  &.abv__exc {
    .graph-main rect {
      fill: $AboveExcellence;
    }
  }

  .abv__exc {
    &.graph-main rect {
      fill: $AboveExcellence;
    }
  }

  &.ocp_metric {
    .graph-main rect {
      fill: $OCPMetric;
    }
  }

  .ocp_metric {
    &.graph-main rect {
      fill: $OCPMetric;
    }
  }
}

.pcg__item:not(:last-child) {
  & > .pcg__item__chart > .graph__container {
    &::after {
      content: '';
      position: absolute;
      top: 10%;
      right: 0;
      height: 60%;
      border-right: 1px solid $color-separator-3;
    }
  }
}

/*TODO: Refactor*/
.metric__block__item:not(:last-child) {
  & > .metric__pcc__chart > .graph__container {
    &::after {
      content: '';
      position: absolute;
      top: 10%;
      right: 0;
      height: 60%;
      border-right: 1px solid $color-separator-3;
    }
  }
}

.formula {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;

  &__right {
    margin-left: 5px;
    white-space: nowrap;

    hr {
      margin: 3px 0;
      border-color: $color-text-primary;
    }
  }

  &__center {
    text-align: center;
  }
}

.formula-smallLeftPadding {
  padding-left: 2px;
}

//PCC by Accounts Tooltip

.data__revenue {
  padding: 10px;
  border: 1px solid $color-separator-3;
  text-align: center;
  margin-top: 15px;

  &__txt {
    color: #828282;
    font-size: 12px;
  }

  &__val {
    color: $color-text-tertiary;
    font-size: 11px;
  }
}

.bar__tooltip__container-pcc {
  .data__source__container {
    max-height: 100px;
    overflow-y: auto;
  }
}

//Line chart styles

.line__chart {
  .line {
    fill: none;
    stroke: #27ae60;
    stroke-width: 5;
  }

  .line_actuals {
    fill: none;
    stroke: #00ae56;
    stroke-width: 2;
    &-dasharray {
      stroke-dasharray: 5, 5;
    }
  }

  .additional__line {
    fill: none;
    stroke: #2f80ed;
    stroke-width: 5;
  }

  .additional__line__Old {
    fill: none;
    stroke: #707070;
    stroke-width: 5;
  }

  .stroke__line {
    fill: none;
    stroke: #27ae60;
    stroke-dasharray: 5, 5;
  }

  .stroke__line_quota {
    fill: none;
    stroke: #3354a5;
    stroke-width: 2;
  }

  .additional__stroke__line {
    fill: none;
    stroke: #2f80ed;
    stroke-dasharray: 5, 5;
  }

  .additional__stroke__line__Old {
    fill: none;
    stroke: #707070;
    stroke-dasharray: 5, 5;
  }

  .dot {
    fill: #27ae60;
    stroke: #fff;
  }

  .dot_actuals {
    fill: #00ae56;
    stroke: #fff;
  }

  .dot_quota {
    fill: #3354a5;
    stroke: #fff;
  }

  .additional__dot {
    fill: #2f80ed;
    stroke: #fff;
  }

  .additional__dot__Old {
    fill: #707070;
    stroke: #fff;
  }

  .attainment__dot {
    fill: $AboveThreshold;
    stroke: $AboveThresholdBorderColor;
  }

  text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
  }

  .tick line {
    stroke: #e0e0e0;
  }

  .x-axis > path {
    display: none;
  }

  .x-axis .tick:first-child line {
    display: none;
  }

  .x-axis .tick:not(:first-of-type) line {
    stroke-dasharray: 5, 5;
  }

  .x-axis .tick:nth-child(odd) line {
    display: none;
  }

  .y-axis > path {
    display: none;
  }

  .y-axis .tick:not(:first-of-type) line {
    stroke-dasharray: 5, 5;
  }

  .y-axis .tick:nth-child(odd) line {
    display: none;
  }

  .axis__label {
    text-anchor: middle;
    color: $color-text-primary;
    font-size: 10px;
  }

  .tooltip__container {
    display: flex;
    justify-content: center;
  }

  .tooltip {
    padding: 5px 10px;
    background: $color-background;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
    border-radius: 2px;
    display: inline-block;
    font-size: 11px;
    position: relative;
    margin-bottom: 10px;
    opacity: 0.8;
    border: 1px solid #ddd;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: calc(100% - 5px);
      border: 5px solid $color-background;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      transform: translatex(-50%) rotate(45deg);
    }

    &.tooltip__horizontal {
      &::after {
        left: 100%;
        top: calc(50% - 5px);
        transform: translatex(-50%) rotate(315deg);
      }
    }

    &__lbl {
      color: $color-text-secondary;
    }

    &.tooltip__ppi {
      &::after {
        content: '';
        position: absolute;
        left: 92%;
        top: calc(100% - 5px);
        border: 5px solid $color-background;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
        transform: translatex(-50%) rotate(45deg);
      }
    }
  }

  .tooltip__container.tooltip__container-bottom {
    .tooltip {
      box-shadow: 0px -0.3px 0.9px rgba(0, 0, 0, 0.108), 0px -1.6px 3.6px rgba(0, 0, 0, 0.132);
      margin-bottom: 0px;
      margin-top: 10px;

      &::after {
        top: -5px;
        box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
      }

      &.tooltip__horizontal {
        &::after {
          top: 15px;
          left: 0px;
        }
      }
    }
  }
}

// Custom CSS for high contrast mode
@media screen and (-ms-high-contrast: Active) {
}

@media screen and (-ms-high-contrast: black-on-white) {
  .line__chart {
    text {
      color: #000000 !important;
    }
    .axis__label {
      color: #000000 !important;
    }
  }
}

@media screen and (-ms-high-contrast: white-on-black) {
  .line__chart {
    text {
      color: #ffffff !important;
    }
    .axis__label {
      color: #ffffff !important;
    }
  }
}
