@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@pagetour/sdk/dist/css/pagetour.css';
.graph__container {
  position: relative;
  overflow-x: auto;
  width: 100%;
}

rect.bar-placeholder {
  fill: #f2f2f2;
}

.bar-clickable {
  cursor: pointer;
}

.x-axis .tick text tspan {
  font-family: "Segoe UI";
  font-size: 12px;
  color: #333333;
}

.y-axis .tick:not(:first-of-type) line {
  stroke-dasharray: 5, 5;
}

.y-axis .tick:nth-child(odd) line {
  display: none;
}

.bar__label text,
text.bar__label {
  text-anchor: middle;
  font-size: 12px;
  fill: #333333;
}

g.layer ~ g.layer .bar-placeholder {
  display: none;
}

.wrap__text {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.wrap__text div {
  font-family: "Segoe UI";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #333333;
  font-weight: 500;
}

.bar__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  font-family: "Segoe UI";
  line-height: normal;
  position: absolute;
  display: none;
  z-index: 200;
}
.bar__tooltip__container {
  display: flex;
  padding: 15px;
}
.bar__tooltip__container-pcg {
  min-width: 500px;
  justify-content: space-between;
}
.bar__tooltip__container-pcc {
  width: 320px;
  flex-direction: column;
}
.bar__tooltip__container-pccAcc {
  width: 280px;
  flex-direction: column;
}
.bar__tooltip__left {
  border-right: 1px solid #f2f2f2;
  padding-right: 10px;
}
.bar__tooltip__right {
  padding-left: 15px;
}
.bar__tooltip__close {
  margin: 0 5px 0 10px;
  align-self: flex-start;
}
.bar__tooltip__close svg {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.bar__tooltip__header {
  font-family: inherit;
  line-height: inherit;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar__tooltip-left.bar__tooltip::before {
  display: none;
}

.chart__tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  font-family: "Segoe UI";
  line-height: normal;
  position: absolute;
  display: none;
  z-index: 200;
  padding-left: 10px;
  padding-right: 10px;
}

.perf__data {
  font-family: inherit;
  line-height: inherit;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.perf__data__text {
  color: #828282;
}

.perf__data__val {
  color: #333333;
  margin-left: 5px;
}

.data__source__item {
  font-family: inherit;
  line-height: inherit;
  font-size: 12px;
}

.data__source__item:not(:last-child) {
  margin-bottom: 10px;
}

.data__source__header {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
}

.data__source__value {
  color: #4f4f4f;
  font-size: 11px;
}

.pcg__item__chart.blw__th .graph-main rect {
  fill: #eb5757;
}
.pcg__item__chart .blw__th.graph-main rect {
  fill: #eb5757;
}
.pcg__item__chart.abv__th .graph-main rect {
  fill: #ffb900;
  stroke: #d77f00;
}
.pcg__item__chart .abv__th.graph-main rect {
  fill: #ffb900;
  stroke: #d77f00;
}
.pcg__item__chart.abv__tgt .graph-main rect {
  fill: #27ae60;
}
.pcg__item__chart .abv__tgt.graph-main rect {
  fill: #27ae60;
}
.pcg__item__chart.abv__exc .graph-main rect {
  fill: #2f80ed;
}
.pcg__item__chart .abv__exc.graph-main rect {
  fill: #2f80ed;
}
.pcg__item__chart.ocp_metric .graph-main rect {
  fill: #999999;
}
.pcg__item__chart .ocp_metric.graph-main rect {
  fill: #999999;
}

.pcg__item:not(:last-child) > .pcg__item__chart > .graph__container::after {
  content: "";
  position: absolute;
  top: 10%;
  right: 0;
  height: 60%;
  border-right: 1px solid #e0e0e0;
}

/*TODO: Refactor*/
.metric__block__item:not(:last-child) > .metric__pcc__chart > .graph__container::after {
  content: "";
  position: absolute;
  top: 10%;
  right: 0;
  height: 60%;
  border-right: 1px solid #e0e0e0;
}

.formula {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
}
.formula__right {
  margin-left: 5px;
  white-space: nowrap;
}
.formula__right hr {
  margin: 3px 0;
  border-color: #333333;
}
.formula__center {
  text-align: center;
}

.formula-smallLeftPadding {
  padding-left: 2px;
}

.data__revenue {
  padding: 10px;
  border: 1px solid #e0e0e0;
  text-align: center;
  margin-top: 15px;
}
.data__revenue__txt {
  color: #828282;
  font-size: 12px;
}
.data__revenue__val {
  color: #4f4f4f;
  font-size: 11px;
}

.bar__tooltip__container-pcc .data__source__container {
  max-height: 100px;
  overflow-y: auto;
}

.line__chart .line {
  fill: none;
  stroke: #27ae60;
  stroke-width: 5;
}
.line__chart .line_actuals {
  fill: none;
  stroke: #00ae56;
  stroke-width: 2;
}
.line__chart .line_actuals-dasharray {
  stroke-dasharray: 5, 5;
}
.line__chart .additional__line {
  fill: none;
  stroke: #2f80ed;
  stroke-width: 5;
}
.line__chart .additional__line__Old {
  fill: none;
  stroke: #707070;
  stroke-width: 5;
}
.line__chart .stroke__line {
  fill: none;
  stroke: #27ae60;
  stroke-dasharray: 5, 5;
}
.line__chart .stroke__line_quota {
  fill: none;
  stroke: #3354a5;
  stroke-width: 2;
}
.line__chart .additional__stroke__line {
  fill: none;
  stroke: #2f80ed;
  stroke-dasharray: 5, 5;
}
.line__chart .additional__stroke__line__Old {
  fill: none;
  stroke: #707070;
  stroke-dasharray: 5, 5;
}
.line__chart .dot {
  fill: #27ae60;
  stroke: #fff;
}
.line__chart .dot_actuals {
  fill: #00ae56;
  stroke: #fff;
}
.line__chart .dot_quota {
  fill: #3354a5;
  stroke: #fff;
}
.line__chart .additional__dot {
  fill: #2f80ed;
  stroke: #fff;
}
.line__chart .additional__dot__Old {
  fill: #707070;
  stroke: #fff;
}
.line__chart .attainment__dot {
  fill: #ffb900;
  stroke: #d77f00;
}
.line__chart text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
}
.line__chart .tick line {
  stroke: #e0e0e0;
}
.line__chart .x-axis > path {
  display: none;
}
.line__chart .x-axis .tick:first-child line {
  display: none;
}
.line__chart .x-axis .tick:not(:first-of-type) line {
  stroke-dasharray: 5, 5;
}
.line__chart .x-axis .tick:nth-child(odd) line {
  display: none;
}
.line__chart .y-axis > path {
  display: none;
}
.line__chart .y-axis .tick:not(:first-of-type) line {
  stroke-dasharray: 5, 5;
}
.line__chart .y-axis .tick:nth-child(odd) line {
  display: none;
}
.line__chart .axis__label {
  text-anchor: middle;
  color: #333333;
  font-size: 10px;
}
.line__chart .tooltip__container {
  display: flex;
  justify-content: center;
}
.line__chart .tooltip {
  padding: 5px 10px;
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
  border-radius: 2px;
  display: inline-block;
  font-size: 11px;
  position: relative;
  margin-bottom: 10px;
  opacity: 0.8;
  border: 1px solid #ddd;
}
.line__chart .tooltip::after {
  content: "";
  position: absolute;
  left: 50%;
  top: calc(100% - 5px);
  border: 5px solid #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  transform: translatex(-50%) rotate(45deg);
}
.line__chart .tooltip.tooltip__horizontal::after {
  left: 100%;
  top: calc(50% - 5px);
  transform: translatex(-50%) rotate(315deg);
}
.line__chart .tooltip__lbl {
  color: #707070;
}
.line__chart .tooltip.tooltip__ppi::after {
  content: "";
  position: absolute;
  left: 92%;
  top: calc(100% - 5px);
  border: 5px solid #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  transform: translatex(-50%) rotate(45deg);
}
.line__chart .tooltip__container.tooltip__container-bottom .tooltip {
  box-shadow: 0px -0.3px 0.9px rgba(0, 0, 0, 0.108), 0px -1.6px 3.6px rgba(0, 0, 0, 0.132);
  margin-bottom: 0px;
  margin-top: 10px;
}
.line__chart .tooltip__container.tooltip__container-bottom .tooltip::after {
  top: -5px;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
}
.line__chart .tooltip__container.tooltip__container-bottom .tooltip.tooltip__horizontal::after {
  top: 15px;
  left: 0px;
}

@media screen and (-ms-high-contrast: black-on-white) {
  .line__chart text {
    color: #000000 !important;
  }
  .line__chart .axis__label {
    color: #000000 !important;
  }
}
@media screen and (-ms-high-contrast: white-on-black) {
  .line__chart text {
    color: #ffffff !important;
  }
  .line__chart .axis__label {
    color: #ffffff !important;
  }
}
::ng-deep .mat-progress-bar-fill::after {
  background-color: #26873a;
}

::ng-deep .mat-progress-bar-buffer {
  background: #e4e8eb;
}

::ng-deep .mat-progress-bar {
  height: 3px;
}

.content__loader {
  padding: 10px;
}
.content__loader-chart {
  width: 700px;
}

.app__container {
  padding: 20px 40px;
}

.k-filter-menu .k-button.k-primary:focus {
  outline-color: #231f20;
}

.app__nav {
  margin-top: 20px;
}
.app__nav__pad {
  margin-top: 40px !important;
  margin-bottom: 30px;
}
.app__nav .nav-pills .nav-link {
  padding-top: 0;
  padding-bottom: 3px;
  color: #707070;
  border-radius: 0;
  font-weight: 500;
  opacity: 1;
}
.app__nav .nav-pills .nav-link:hover {
  background-color: #ffffff;
}
.app__nav .nav-pills .nav-link.active,
.app__nav .nav-pills .show > .nav-link {
  background-color: #fff;
  color: #333333;
  border-bottom: 2px solid #2f80ed;
}
.app__nav .nav-pills .nav-link.active .view__icon path,
.app__nav .nav-pills .show > .nav-link .view__icon path {
  fill: #333333;
}
.app__nav-views {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.app__nav-views .nav-pills .nav-link {
  color: #828282;
}
.app__nav-views_left {
  font-size: 12px;
  display: flex;
  margin-top: 15px;
}
.app__nav-views_left .nav-pills .nav-link {
  color: #828282;
}
.app__nav-manager .nav-link {
  font-size: 20px;
}

.app__tile {
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
  padding: 20px;
  margin: 20px 0;
  font-family: "Segoe UI";
  line-height: normal;
}
.app__tile__ytd {
  padding: 0;
}
.app__tile__container {
  padding: 20px;
}
.app__tile__header {
  display: flex;
  align-items: center;
}
.app__tile__header .app__nav-views {
  margin-left: auto;
  margin-top: 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .app__tile__header {
    flex-wrap: wrap;
  }
}
.app__tile__heading {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
.app__tile__report {
  font-size: 14px;
  padding: 0 5px;
  text-align: right;
  color: #0078d4 !important;
  cursor: pointer;
  margin-left: auto;
  text-decoration: none !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .app__tile__report {
    flex-basis: 100%;
    text-align: left;
    margin-top: 10px;
  }
}

.app__tile__new {
  margin-top: -20px;
}

.app__export__excel {
  display: block;
  position: relative;
}

.legend {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.legend__item {
  display: flex;
  align-items: center;
}
.legend__item:not(:last-child) {
  margin-right: 20px;
}
.legend__item__clr {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  flex-shrink: 0;
}
.legend__item__icon {
  width: 20px;
  font-size: 20px;
  margin-right: 5px;
}
.legend__item__text {
  font-size: 11px;
  color: #4f4f4f;
}

.mint__legends-myteam .legend {
  margin: 0;
}

.tab__content__header {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.tab__content__subhead {
  font-size: 12px;
  color: #707070;
  margin-right: 15px;
}

.stat__info {
  position: relative;
}

.stat__tooltip {
  position: absolute;
  font-weight: normal;
  display: none;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  z-index: 100;
  padding: 15px;
  text-align: left;
  color: #333333;
  width: 200px;
  right: -30px;
  top: 30px;
}
.stat__tooltip__header {
  font-size: 14px;
}
.stat__tooltip__text {
  font-size: 12px;
  margin: 0;
}
.stat__tooltip::before {
  content: "";
  position: absolute;
  right: 28.5px;
  top: -10px;
  border: 10px solid #ffffff;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.stat__tooltip-tt-right::before {
  margin-top: 33px;
  margin-right: 160px;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
}
.stat__tooltip-left {
  left: -15px;
  right: unset;
}
.stat__tooltip-left-tt-right {
  margin-top: -54px;
  margin-right: -176px;
}
.stat__tooltip-left::before {
  right: unset;
}
.stat__tooltip-widen {
  width: 400px;
}
.stat__tooltip-widest {
  width: 620px;
}

.tooltip__trigger {
  font-size: 13px;
  margin-left: 3px;
  color: #828282;
  border: 0;
  background: #ffffff;
  padding: 0;
}

.icon.icon-info {
  font-size: 13px;
  margin-left: 3px;
  color: #828282;
  top: 2px;
  display: block;
}
.icon.icon-info:hover + .stat__tooltip {
  display: block;
}
.icon.icon-info:focus + .stat__tooltip {
  display: block;
}

.pcg__item {
  flex: 1;
}
.pcg__item:not(:last-child) {
  margin-right: 5px;
}
.pcg__item:only-child {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}
.pcg__item-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pcg__item-single__desc {
  border: 1px solid #e0e0e0;
}
.pcg__item__metric {
  box-sizing: border-box;
  flex: 1;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}
.pcg__item__chart {
  display: flex;
}

.mint-modal-backdrop.modal-backdrop.fade {
  opacity: 0.5;
}

.mint-modal-window.show {
  opacity: 1;
}
.mint-modal-window.show .modal-dialog {
  transform: translate(0, 0);
}

.mint-modal-window-center.show {
  opacity: 1;
}
.mint-modal-window-center.show .modal-dialog {
  transform: translate(0, 0);
  margin: 250px auto;
}

.mint-modal-window-enlarge.show {
  opacity: 1;
}
.mint-modal-window-enlarge.show .modal-dialog {
  width: auto;
  margin: 50px 50px;
  transform: translate(0, 0);
}

.modal-centred {
  display: flex !important;
  align-items: center;
}

.mint-modal-window-lg.show {
  opacity: 1;
}
.mint-modal-window-lg.show .modal-dialog {
  transform: translate(0, 0);
  width: 900px;
}

.mint-modal-window-lg-mqch.show {
  opacity: 1;
}
.mint-modal-window-lg-mqch.show .modal-dialog {
  transform: translate(0, 0);
  width: 1100px;
  top: 50px;
}
.mint-modal-window-lg-mqch.show .modal-dialog .modal-content {
  border-radius: 1px;
}

.angular-editor-toolbar {
  font-size: 16px !important;
}

.mint__banner {
  background: #deecf9;
  padding: 10px;
  margin: 10px 20px;
}

.direct__level-1 .direct__item > .direct .utilization__quota__container {
  margin-left: 90px;
}
.direct__level-2 .direct__item > .direct .utilization__quota__container {
  margin-left: 60px;
}
.direct__level-3 .direct__item > .direct .utilization__quota__container {
  margin-left: 30px;
}
.direct__level-4 .direct__item > .direct .utilization__quota__container {
  margin-left: 0;
}

.icon__ms {
  font-size: 20px;
}
.icon__ms.icon-compliance-signed {
  color: #26873a;
}
.icon__ms.icon-compliance-notsigned {
  color: #c50000;
}

.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #707070;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: #707070;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "";
}

[dir=rtl] .slick-prev:before {
  content: "";
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "";
}

[dir=rtl] .slick-next:before {
  content: "";
}

.slick-track {
  margin: 0;
}

.ubi-metric .pcg__item:only-child {
  flex-grow: 1;
}

.perf_metric_card_rubi .rubi_perf_metric_card .metric__weight {
  margin-top: 23px;
}
.perf_metric_card_rubi .rubi_perf_metric_card .metric__stat {
  margin-top: 30px;
}

.multiselect-dropdown .dropdown-btn {
  border-radius: 0 !important;
}

.flex_row .multiselect-dropdown {
  min-width: 400px;
}

.grid__container {
  margin-top: 40px;
}
.grid__container .accordion {
  width: 100%;
}
.grid__container .accordion .card-header {
  background-color: #e5edf3;
  border: 1px solid #c5c5c5;
}
.grid__container .accordion .card-body {
  display: block;
}
.grid__container .accordion .card-body .card-header {
  background-color: #ffffff;
}
.grid__container .accordion .collapse__header {
  padding: 10px 10px;
}
.grid__container .accordion.level-1 .card:not(:first-child):not(:only-child) {
  margin: 0px;
  border: 1px solid #c5c5c5;
}

.accordion {
  width: 100%;
}
.accordion .card-header {
  background-color: #f2f2f2;
}
.accordion .card-body {
  display: flex;
}
.accordion .card-body .card-header {
  background-color: #ffffff;
}
.accordion .collapse__header {
  padding: 24px 10px;
}
.accordion.level-1 .card:not(:first-child):not(:only-child) {
  margin-top: 10px;
}
.accordion.level-1.mt .card:first-child {
  margin-top: 10px;
}
.accordion.level-2 .card:not(:first-child):not(:only-child), .accordion.level-3 .card:not(:first-child):not(:only-child) {
  margin-top: 0;
}
.accordion.level-2 .collapse__header {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
}
.accordion.level-3 .collapse__header {
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
}

.footer__btn {
  border: none;
  width: 76px;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.footer__btn-primary {
  color: #ffffff;
  background: #0078d4;
}
.footer__btn-cancel {
  color: #666666;
  font-size: 13px;
  background: #f3f2f1;
}
.footer__btn:focus {
  outline: 1px dashed #000000;
}

.toggle__btn {
  margin-left: 10px;
}
.toggle__btn__container {
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.toggle__btn__title {
  font-family: inherit;
  line-height: normal;
  color: #323130;
  font-size: 14px;
}
.toggle__btn__title__beta {
  font-size: 10px;
  color: #0078d4;
  font-weight: bold;
}
.toggle__btn__lbl {
  background: #cccccc;
  border-radius: 10px;
  display: block;
  width: 40px;
  height: 20px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  margin-bottom: 0;
}
.toggle__btn__lbl.ytd__lbl {
  background: #0078d4;
}
.toggle__btn__lbl.softcap__lbl {
  background: #a4262c;
}
.toggle__btn__lbl::before {
  content: "";
  position: absolute;
  right: 24px;
  top: 4px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.3s;
}

.toggle__btn__inp:checked + .toggle__btn__lbl {
  background: #0078d4;
  transition: all 0.3s;
}
.toggle__btn__inp:checked + .toggle__btn__lbl.softcap__lbl {
  background: #096e3b;
}
.toggle__btn__inp:checked + .toggle__btn__lbl::before {
  content: "";
  position: absolute;
  right: 4px;
  top: 4px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.3s;
}

.softcap__aproved label.toggle__btn__lbl {
  background: #096e3b !important;
}
.softcap__aproved .toggle__btn__title {
  color: #096e3b;
}
.softcap__declined label.toggle__btn__lbl {
  background: #a4262c !important;
}
.softcap__declined .toggle__btn__title {
  color: #a4262c;
}

.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #000000;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "";
}

[dir=rtl] .slick-prev:before {
  content: "";
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "";
}

[dir=rtl] .slick-next:before {
  content: "";
}

.slick-track {
  margin: 0;
}

::selection {
  background: #0078d7 !important;
  color: #fff !important;
}

::-moz-selection {
  background: #0078d7 !important;
  color: #fff !important;
}

select:focus,
a:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.grid-display {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

/* Tooltip styling */
.customHeaderLabel [data-text] {
  display: inline-block;
  position: relative;
  padding: 0px 2px 15px 2px;
  font-weight: normal;
  opacity: 1;
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none;
  outline: none;
}

/* Tooltip styling */
.customHeaderLabel [data-text]:before {
  content: attr(data-text);
  display: none;
  position: absolute;
  padding: 4px 8px;
  line-height: 1.4;
  min-width: 250px;
  text-align: center;
  background: #ffffff;
  color: #333333;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
  font-family: "Segoe UI";
  line-height: normal;
  padding: 15px;
  text-align: inherit;
  white-space: pre-line;
}

.ag-header-row .ag-header-cell app-custom-header div a[data-text]:before {
  margin: 31px 10px 10px -30px;
}

.ag-header-row .ag-header-cell:last-child app-custom-header div a[data-text]:before {
  margin: 31px 10px 10px -210px;
}

.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  border-right: none;
}

div.ag-root {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Tooltip arrow styling/placement */
.customHeaderLabel [data-text]:after {
  content: "";
  position: absolute;
  display: none;
  left: 31px;
  top: -16px;
  border: 10px solid #ffffff;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 40px 10px 10px -30px;
  min-width: 20px;
}

/* Show the tooltip when hovering */
.customHeaderLabel [data-text]:hover:before,
.customHeaderLabel [data-text]:focus:before {
  display: block;
  z-index: 40;
}

.customHeaderLabel [data-text]:hover:after,
.customHeaderLabel [data-text]:focus:after {
  display: block;
  z-index: 50;
}

.cell-span {
  background-color: #ffffff;
  border-bottom: 1px solid #bdc3c7;
}

.payout-not-done::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #e0e0e0;
  position: absolute;
  left: 0;
}

.payout-done::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #27ae60;
  position: absolute;
  left: 0;
}

.ag-header-viewport,
.ag-header,
.ag-header-row,
.ag-header-cell {
  overflow: unset;
  z-index: 2;
}

.ag-theme-balham .ag-pinned-left-header {
  overflow: unset;
}

.ag-theme-balham .ag-cell.cell-span-below-threshold {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-below-threshold::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #eb5757;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-below-target {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-below-target::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #ffb900;
  border: 1px solid #d77f00;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-below-excellence {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-below-excellence::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #27ae60;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-neutral {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-neutral::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #27ae60;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-above-excellence {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-above-excellence::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #2f80ed;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-rubi-neutral {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-rubi-neutral::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #000000;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-ocp-metric {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-ocp-metric::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #999999;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-past {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-past::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #669da9;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-current {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-current::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #005b70;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-future {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-future::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #bdbdbd;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-locked {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-locked::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #000000;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-cell.cell-span-unlocked {
  background-color: #ffffff;
}
.ag-theme-balham .ag-cell.cell-span-unlocked::before {
  height: calc(100% - 16px);
  content: "";
  width: 4px;
  background: #00ae56;
  position: absolute;
  left: 0;
}

.ag-theme-balham .ag-row-even {
  background-color: unset;
}

.ag-theme-balham .ag-row-odd {
  background-color: unset;
}

.ag-theme-balham .ag-cell {
  border: none;
  border-bottom: 1px solid #bdc3c7;
}

.ag-theme-balham.plan__territory__grid .ag-header,
.ag-theme-balham.plan__territory__grid .ag-header-row {
  border: none;
}
.ag-theme-balham.plan__territory__grid .ag-cell {
  border-bottom: 1px solid #edebe9;
}

.ag-theme-balham .ag-row {
  border: none;
}

.ag-cell {
  text-overflow: unset;
  white-space: unset;
  word-break: break-word;
  display: flex;
  align-items: center;
  overflow: unset;
}

.ag-row {
  white-space: unset;
  word-break: break-word;
}

.ag-theme-balham .ag-root {
  border: unset;
}

.ag-theme-balham .ag-header {
  background-color: unset;
  font: 600 12px "Segoe UI";
  color: #707070;
}

.ag-theme-balham.ubi__report__grid .ag-header-group-cell-with-group {
  border: 1px solid #e0e0e0 !important;
  line-height: 40px;
}
.ag-theme-balham.ubi__report__grid .ag-header-group-cell-with-group .ag-header-group-cell-label {
  justify-content: center;
}
.ag-theme-balham.ubi__report__grid .ag-header-row:first-child {
  border: 0;
}
.ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) {
  border-color: #edebe9;
}
.ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) .ag-header-cell {
  border: 0;
  line-height: 65px;
}
.ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) .ag-header-cell:not(:first-child) .ag-header-cell-label {
  justify-content: flex-end;
  float: right;
}
.ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) .ag-header-cell:nth-last-child(1), .ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) .ag-header-cell:nth-last-child(2), .ag-theme-balham.ubi__report__grid .ag-header-row:nth-child(2) .ag-header-cell:nth-last-child(3) {
  background: #fcfcfc;
}
.ag-theme-balham.ubi__report__grid .ag-cell {
  border-color: #edebe9;
  color: #333333;
  line-height: 50px;
}
.ag-theme-balham.ubi__report__grid .ubi__col__val {
  justify-content: flex-end;
}
.ag-theme-balham.ubi__report__grid .ubi__actual__hrs {
  background: #fcfcfc;
}
.ag-theme-balham.ubi__report__grid .ag-floating-bottom-container .ag-row {
  background: #fafafa;
}
.ag-theme-balham.ubi__report__grid .ag-floating-bottom-container .ag-row:first-child {
  background-color: rgba(242, 242, 242, 0.35);
}
.ag-theme-balham.ubi__report__grid .ag-floating-bottom-container .ag-row:first-child .ag-cell {
  border-top: 1px solid #999999;
}
.ag-theme-balham.ubi__report__grid .ag-floating-bottom-container .ag-cell {
  border-bottom: 1px solid #000000;
}

.bar__tooltip__container-pcc .data__source__item:not(:first-child) {
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
}
.bar__tooltip__container-pcc .data__source__header {
  margin-bottom: 8px;
}

.pcg__item__metric.blw__th {
  border: 1px solid #eb5757;
  border-top: None;
}
.pcg__item__metric.blw__th .metric__weight__val {
  border: 1px solid #eb5757;
}
.pcg__item__metric.abv__th {
  border: 1px solid #d77f00;
  border-top: None;
}
.pcg__item__metric.abv__th .metric__weight__val {
  border: 1px solid #d77f00;
}
.pcg__item__metric.abv__tgt {
  border: 1px solid #27ae60;
  border-top: None;
}
.pcg__item__metric.abv__tgt .metric__weight__val {
  border: 1px solid #27ae60;
}
.pcg__item__metric.abv__exc {
  border: 1px solid #2f80ed;
  border-top: None;
}
.pcg__item__metric.abv__exc .metric__weight__val {
  border: 1px solid #2f80ed;
}
.pcg__item__metric.neutral {
  border: 1px solid #27ae60;
  border-top: None;
}
.pcg__item__metric.neutral .metric__weight__val {
  border: 1px solid #27ae60;
}
.pcg__item__metric.rubi_neutral {
  border: 1px solid #000000;
  border-top: None;
}
.pcg__item__metric.rubi_neutral .metric__weight__val {
  border: 1px solid #000000;
}
.pcg__item__metric.ocp_metric {
  border: 1px solid #999999;
  border-top: None;
}
.pcg__item__metric.ocp_metric .metric__weight__val {
  border: 1px solid #999999;
}
.pcg__item__metric.estimator_buckets {
  border: 1px solid #00ae56;
  border-top: None;
}
.pcg__item__metric.estimator_buckets .metric__weight__val {
  border: 1px solid #00ae56;
}

.pcg__item__metric__top__border__blw__th {
  height: 8px;
  background-color: #eb5757;
}
.pcg__item__metric__top__border__abv__th {
  height: 8px;
  background-color: #ffb900;
  border: 1px solid #d77f00;
}
.pcg__item__metric__top__border__abv__tgt {
  height: 8px;
  background-color: #27ae60;
}
.pcg__item__metric__top__border__abv__exc {
  height: 8px;
  background-color: #2f80ed;
}
.pcg__item__metric__top__border__neutral {
  height: 8px;
  background-color: #27ae60;
}
.pcg__item__metric__top__border__rubi_neutral {
  height: 8px;
  background-color: #000000;
}
.pcg__item__metric__top__border__ocp_metric {
  height: 8px;
  background-color: #999999;
}
.pcg__item__metric__top__border__estimator_buckets {
  height: 8px;
  background-color: #00ae56;
}
.pcg__item__metric__top__border__estimator_estimated_earnings {
  height: 8px;
  background-color: #212121;
}

.incentive__history .app__tile {
  margin-bottom: 100px;
}

.tour-link {
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.m-l-15 {
  margin-left: 15px;
}

#grid-header-cell-text-wrap {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}

.ag-header-cell[col-id=attainment] {
  line-height: unset;
}
.ag-header-cell[col-id=payoutPercentage] {
  line-height: unset;
}
.ag-header-cell[col-id=payoutAtThreshold] {
  line-height: unset;
}
.ag-header-cell[col-id=multiplierThresholdToTarget] {
  line-height: unset;
}
.ag-header-cell[col-id=multiplierTargetToExcellence] {
  line-height: unset;
}

.ag-grid__link__cell:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.rubi_perf_metric_card {
  border: 1px solid black;
  border-top: 8px solid black;
  height: 137px;
}

.rpart_perf_metric_card {
  border: 1px solid black;
  border-top: 8px solid black;
  height: 137px;
}

.cagr__tabset .nav-pills {
  display: flex;
}
.cagr__tabset .nav-item {
  flex: 1;
}
.cagr__tabset .nav-item:not(:first-child):not(:only-child) {
  margin-left: 20px;
}
.cagr__tabset .nav-link {
  color: initial;
  border-radius: 0;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background: url("./assets/cagr_bg_2.svg");
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
}
.cagr__tabset .nav-link:hover, .cagr__tabset .nav-link:focus {
  background-color: #ffffff;
  color: initial;
  opacity: 1;
}
.cagr__tabset .nav-link .active__overlay {
  display: none;
}
.cagr__tabset .nav-link.active {
  outline: 3px solid #0078d7;
  outline-offset: 0;
}
.cagr__tabset .nav-link.active::before {
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - 13px);
  width: 0px;
  height: 0px;
  border: 13px solid #0078d7;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom: none;
}
.cagr__tabset .nav-link.active .active__overlay {
  display: block;
}

.quota-grid {
  padding: 0px 20px;
  margin-top: 20px;
}
.quota-grid .app-table.rbi-table th {
  vertical-align: top;
  border-bottom: 1px solid #edebe9;
  color: #707070;
  font-weight: 700;
  padding: 10px 10px;
  font-size: 12px;
}
.quota-grid .app-table.rbi-table .app-table-rows.rbi-table-rows td {
  border-bottom: 1px solid #edebe9;
  vertical-align: middle;
  padding: 15px 10px;
}
.quota-grid .app-table.rbi-table .app-table-rows.rbi-table-rows td:last-child {
  border-right: none;
}

.ag-layout-auto-height {
  min-height: 230px;
}