//Overwriting material's progress bar's default foreground and background colors
::ng-deep .mat-progress-bar-fill::after {
  background-color: #26873a;
}

::ng-deep .mat-progress-bar-buffer {
  background: #e4e8eb;
}

::ng-deep .mat-progress-bar {
  height: 3px;
}

.content__loader {
  padding: 10px;

  &-chart {
    width: 700px;
  }
}
